.w-fill {
    min-height: 100vh;
    min-width: 100vw;
}

.center{
    width: 80vw;
}

.c-primary {
    background-color: rgb(224, 107, 25);
}
